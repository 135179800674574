@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;669;700;800;900&display=swap');

:root {
  --primary-color: #16947B;
  --secondary-color: #0B5F37;
  --text-color: #343a41;
}

* {
  font-family: "Maven Pro", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;  
}

#root {
  height: 100%;
}

p {
  margin: 0
}

.h-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.v-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.w-100 {
  width: 100%
}

.w-90 {
  width: 90%
}

.w-80 {
  width: 80%;
  max-width: 1000px
}

.w-60 {
  width: 60%;
  max-width: 1000px
}

.w-50 {
  width: 50%;
  max-width: 1000px
}

.w-30 {
  width: 30%;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-10 {
  margin-bottom: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}


.error {
  color: red;
}

.test {
  background: red;
}