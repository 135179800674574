form.form-range-date {
  display: flex;    
  width: 90%;
  margin-bottom: 50px;
}

form.form-range-date .container-input {
  display: flex;
  flex-direction: column;
  margin-right: 30px; 
  font-size: 16px;
}

form.form-range-date .container-input input{
  height: 35px;
  width: 180px;
  font-size: 16px;
}

@media(max-width: 1024px) {
  form.form-range-date {   
    width: 95%;
    display: flex;        
  }

  form.form-range-date .container-input {
    margin-right: 0; 
    margin-bottom: 10px;
    width: 100%;
  }

  form.form-range-date .container-input input,.select{
    height: 30px;    
  }
}