.container-tickets {
  width: 90%;
  max-width: 1000px;
}

.container-tickets h2{
  color: var(--secondary-color);
  margin-bottom: 40px;
  align-self: flex-start;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .container-tickets {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-tickets h2{
    margin-left: 15px;
  }

  
}

