.container-transactions {
  width: 90%;
  max-width: 1000px;
}

.container-transactions h2{
  color: var(--secondary-color);
  margin-bottom: 40px;
  align-self: flex-start;
  margin-top: 50px;
}

@media(max-width: 1024px) {
  .container-transactions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-transactions h2{
    margin: 0;
    margin: 20px 0;
    margin-left: 2%;
  }

}