.container-ticket-details {
  width: 90%;
  max-width: 1000px;
}

.container-ticket-details h2 {
  color: var(--secondary-color);
  margin-bottom: 40px;
  align-self: flex-start;
  margin-top: 50px;
}

.container-ticket-details .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin-bottom: 40px;
}

.container-ticket-details .details input {
  color: var(--secondary-color);
}

.container-ticket-details .details .skeleton {
  width: 500px;
  height: 50px;
}
.container-ticket-details .files {
  display: flex;
}

.container-ticket-details .files a {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-right: 10px;
}

.container-ticket-details .container-button {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

#container-modal-confirm {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  min-height: 400px;
  height: auto;
  background: #e2e2e2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 9px;
}

#container-modal-confirm h3 {
  color: var(--secondary-color);
  text-align: center;
}

#container-modal-confirm span {
  width: 70%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--secondary-color);
}

#container-modal-confirm .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 80%;  
}

#container-modal-confirm button {
  border: none;
  background-color: var(--primary-color);
  height: 30px;
  width: 90%;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  margin-top: 25px;
  padding: 0 20px;
  cursor: pointer;  
}

#container-modal-confirm .row-buttons {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#container-modal-confirm .row-buttons button {
  width: 250px;
}

.container-ticket-details .container-button button {
  border: none;
  background-color: var(--primary-color);
  height: 30px;
  width: 300px;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  margin-top: 25px;
  padding: 0 20px;
  cursor: pointer;
}

@media(max-width: 1024px) {
  .container-ticket-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-ticket-details .details {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .container-ticket-details .details .MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;    
  }

  .container-ticket-details .details .skeleton {
    width: 100%;
    margin-bottom: 15px;   
    height: 60px; 
  }

  .container-ticket-details h2 {
    margin-left: 20px;
  }

  .container-ticket-details .files {
    justify-content: flex-start;
    width: 90%;    
  }

  .container-ticket-details .files a svg {
    color: var(--secondary-color);
  }

  .container-ticket-details .container-button {
    width: 100%;
    justify-content: center;
  }

  .container-ticket-details .container-button button {
    height: 40px;
  }

  #container-modal-confirm .row-buttons {
    width: 100%;
  }

  #container-modal-confirm .row-buttons button {
    width: 48%;
    height: 50px;
  }
}