.container-send-link-payment {
  width: 90%;  
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-send-link-payment a {
  display: none;
}

.container-send-link-payment h2 {
  color: var(--secondary-color);  
  align-self: flex-start;
  margin-top: 50px;
}

.container-send-link-payment section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 10px 0 10px;
}

.container-send-link-payment section div {  
  width: 100%;    
  display: flex;
  flex-direction: row;  
  margin-bottom: 20px;
}

.container-send-link-payment section button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
}

.container-send-link-payment form {
  width: 40%;
  align-self: flex-start;
}

.container-send-link-payment .container-buttons {  
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-self: flex-start;
  width: 100%;
}

.container-send-link-payment section div p {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  margin: 0;
  padding: 0;  
}

.container-send-link-payment .container-buttons button {
  border: none;
  outline: none;
  background-color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  height: 30px;
  width: 190px;
  margin: 0 5px;  
}

.container-send-link-payment .container-buttons button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 800px) {
  .container-send-link-payment {
    width: 100%;
  }

  .container-send-link-payment h2 {
    margin-left: 2%;
  }

  .container-send-link-payment section {
    width: 95%;
  }

  .container-send-link-payment section div {
    margin-top: 15px;
    width: 100%;    
    display: flex;
    flex-direction: row;    
  }

  .container-send-link-payment section div p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    margin: 0;
    padding: 0;  
  }

  .container-send-link-payment section div button {
    margin: 0;
    margin-left: 30px;        
  }

  .container-send-link-payment form {
    width: 95%;
    align-self: center;
  }

  .container-send-link-payment .container-buttons {
    width: 100%;
  }

  .container-send-link-payment .container-buttons button {
    width: 100%;
  }
}
