.form-payment {
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-payment h2 {
  color: var(--secondary-color);
  margin-bottom: 40px;
  align-self: flex-start;
  margin-top: 50px;
}

.form-payment form {
  display: flex;
}

.form-payment .MuiFormControl-root.MuiTextField-root.w-100.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  box-shadow: 0 0 3px rgba(0, 0, 0, .16);
  outline: none;
}

.form-payment section {  
  min-width: 350px;
  margin: 0 50px 0 10px;
}

.form-payment form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.form-payment form button {
  border: none;
  outline: none;
  background-color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  height: 30px;
  width: 100%;
  margin-top: 15px;  
}

.form-payment form button:hover {
  background-color: var(--secondary-color);
}

@media(max-width: 1024px) {
  .form-payment {
    width: 100%;
  }

  .form-payment h2 {
    margin-left: 2%;
  }

  .form-payment form {
    width: 95%;
  }

  .form-payment section {
    width: 100%;
    margin: 0;
  }

  .form-payment form .container-buttons {
    width: 100%;
  }

  .form-payment form .container-buttons button {
    font-size: 13px;
    width: 90%;
    height: 45px;
  }


}