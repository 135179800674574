.container-login{  	
  display: flex;	
  flex-direction: row-reverse;	
  height: 100%;	
  background: #fefefe;	
}	

.container-login .column-logo div.border{	
  background-color: var(--primary-color);	
  width: 100%;	
  z-index: 10;  	
  padding: 0;	
  top: 0;	
  left: 0;	

  display: flex;	
  flex-direction: column;	
  justify-content: center;	
  align-items: center;	
  border-radius: 10%;	
}	

.column-logo{	
  background-color: var(--primary-color);	
  width: 60%;	
  z-index: 10;  	
  padding: 0;	
  top: 0;	
  left: 0;	

  display: flex;	
  flex-direction: column;	
  justify-content: center;	
  align-items: center;	
  padding: 30px;	
}	

@keyframes logo {	
  0%   {opacity: 0;}	
  100% {opacity: 1;}	
}	
.column-logo #logo{	
  margin-top: -50px;	
  height: 120px;	
  margin-bottom: 15px;	
  animation-name: logo;	
  animation-duration: 4s;	
}	

@keyframes helpmaney {	
  0% { margin-left: -100%;}	
  100% { margin-left: 0 }	
}	

@keyframes pay {	
  0% { margin-right: -100%;}	
  100% { margin-right: 0 }	
}	
.helpmaney{	
  display: flex;	
  animation-name: helpmaney;	
  animation-duration: 2s;	
}	
.column-logo h2{	
  font-size: 45px;	
  font-weight: bold;	
  color: #fff;	
  margin: 0;	
}	

.column-logo p {	
  margin: 0;	
  color: #fff;	
  font-size: 18px;	
  animation-name: pay;	
  animation-duration: 2s;	
}	
.helpmaney #help{	
  color: #fff;	
}	
.helpmaney #maney{	
  color: #fff	
}	

.column-form{	
  display: flex;	
  flex-direction: column;	
  align-items: center;	
  justify-content: center;	
  width: 60%;	
}	

.column-form h1{	
  font-size: 60px;	
  font-weight: bold;	
  color: var(--primary-color);	
  margin: 0;	
}	

.column-form .acesse{	
  display: flex;	
  width: 60%;	
  align-self: center;	
  justify-content: center;	
  align-items: center;	
  margin: 0;	
}	
.column-form .acesse hr{	
  border-color: var(--primary-color);	
  width: 8%;	
  margin: 0;	
}	

.column-form .acesse h4{	
  font-size: 16px;	
  margin-right: 15px;	
  margin-left: 15px;	
  color: var(--primary-color);	
  margin-top: 18px;	
}	

.column-form form{	
  width: 40%;	
  margin: 20px 0;	
}	

.column-form form input {	
  background: #fff;	
  color: var(--text-color);  	
}	

.column-form form button[type=submit]{	
  border: none;	
  background-color: var(--primary-color);	
  width: 100%;	
  height: 40px;	
  font-size: 22px;	
  color: #fff;	
  border-radius: 5px;	
  outline: none;	
  margin-top: 25px;	
}	
.column-form form button[type=submit]:hover{	
  background-color: var(--secondary-color);	
}	
.column-form form p{	
  color: red;	
  text-align: center;	
  height: 10px;	
  transition-duration: 0.5s;	
  margin-top: 5px;	
}	
#esquece{	
  margin-top: 3px;	
  border: none;	
  background: none;	
  color: var(--primary-color);	
  outline: none;	
}	

@media(max-width: 800px){	
  .container-login .column-logo {	
    display: none;	
  }	

  .container-login .column-form {	
    width: 100%;	
  }	

  .container-login .column-form form{	
    width: 80%;	
  }	

  .column-form .acesse{	
    width: 90%;	
  }	
}	
