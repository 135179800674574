.container-services {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-services h2 {
  margin-left: 15px;
  align-self: flex-start;
}

.container-services .container-buttons {
  display: flex;
  flex-direction: column;  
  width: 90%;  
  margin-top: 40px;
}

.container-services .container-buttons button {
  border: none;
  background-color: var(--primary-color);
  height: 45px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  margin-bottom: 45px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}