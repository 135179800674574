.home {
  display: flex;
  flex-direction: column;    
  margin-top: 50px;  
  width: 100%;
  align-items: center;
}

.home button {
  border: none;
  background: none;
  outline: none;
}

.home .welcome {
  width: 80%;
  max-width: 1000px;
}
.home .card__home {
  /* box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%); */
  box-shadow: 0 0 6px rgba(0,0,0,.16);
  width: 45%;
  padding: 13px;
  border-radius: 8px;
  margin: 30px 0;
  min-width: 250px;
  background: #fff;
}

.home .balance {
  width: 80%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
}

.home .balance .balance_title {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0;
  align-self: flex-start;
}

.home .balance .balance_secondary {
  font-size: 12px;
  font-weight: 500;
  color: #02d654;
  margin: 0;
  margin-top: 10px;
  display: flex;
}

.home .balance .balance_secondary p {
  margin: 0;
  margin-right: 5px;
  margin-top: 2px;
}

.balance button {
  color: var(--secondary-color);
  align-self: flex-start;
  cursor: pointer;
}

.home .activity {
  width: 80%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.last_transactions .transactions__body {
  width: 100%;
}

.statement__item {
  display: flex;  
  width: 100%;
  justify-content: space-between;
  margin: 5px 0;
}

.statement__item p {  
  width: 120px;
  text-align: end;
}

.statement__body {
  width: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.last_transactions .transactions__header {
  width: 90%;
}

.last_transactions .transactions__header p {
  margin: 0;
}

.last_transactions .transactions__header hr {
  margin: 0 0 15px 0;
}



.last_receiveds {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.last_receiveds .receiveds__header {
  width: 90%;
}

.last_receiveds .receiveds__header p {
  margin: 0;
}

.last_receiveds .receiveds__header hr {
  margin: 0 0 15px 0;
}

.home .quick__options {
  display: none;
}

@media(max-width: 1024px) {
  .home {
    padding: 10px;
  }

 
  .home .activity {
    width: 100%;
    padding: 0;
  }

  .home .activity .last_transactions {
    width: 100%;
  }

  .home .card__home {
    margin: 10px 0;
    width: 100%;
  }

  .home .balance.card__home {
    width: 100%;
  }

  .home .balance .balance_secondary svg {
    display: none;
  }

  .home {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home .welcome {
    min-width: 0px;
    align-self: flex-start;
  }

  .home .quick__options {  
    display: flex;
    justify-content: space-between;
    align-items: flex-start;         
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;   
    width: 100%;  
  }
  
  .home .quick__options .quick__options__item {
    background: var(--primary-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    color: #eee;
    margin: 0 10px;
    min-width: 100px;
    height: 100px;
  }
  
  .quick__options .quick__options__item p {
    margin-top: 10px;
    text-decoration: none;
    color: #fff;
  }
  
  .quick__options .quick__options__item img,
  svg {    
    font-size: 50px;
    color: #eee;
  } 


}