.table-installments {
  width: 500px;
  margin: -70px 10px 0 10px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .16);
  background: #fff;
}

.table-installments.mobile {
  display: none;
}

.table-installments table {
  width: 100%;
  border-collapse: collapse;
}

.table-installments table,
th {
  font-size: 14px;
}

.table-installments table {
  border: 1px solid #ddd;
  color: var(--font-color);
  font-size: 16px;
  min-width: 40px;
}

.table-installments td {
  border: 1px solid #ddd;
  color: var(--font-color);
  font-size: 16px;
  min-width: 40px;
}

.table-installments th {
  text-align: center;
  height: 35px;
}

.table-installments td {
  text-align: center;
  height: 35px;
}

.table-installments th {
  background: #fff;
}

.table-installments tr:hover {
  background: #eee;
}

@media(max-width: 1024px) {
  .table-installments.desktop {
    display: none;
  }

  .table-installments.mobile {
    display: block;
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }

  .table-installments table {
    font-size: 14px;
  }

  .table-installments td {
    font-size: 14px;
  }


  .table-installments th {
    font-size: 14px;
    width: auto;
  }

  .table-installments td {
    font-size: 14px;
    width: auto;
  }

}