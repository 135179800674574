.client-link-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.client-link-payment-container header {
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 50px;
  color: var(--secondary-color);
}

.client-link-payment-container header h1 {
  margin: 0;
  margin-bottom: 10px;
}

.client-link-payment-container h3 {
  color: var(--secondary-color);
}

.client-link-payment-container header img {
  width: 120px;
  height: 120px;
}

.client-link-payment-container form {
  max-width: 500px;
  width: 90%;  
}

.client-link-payment-container form .container-input {
  margin-top: 20px;
  margin-bottom: 20px;  
}

.client-link-payment-container form button {
  margin-top: 20px;
  max-width: 500px;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.client-link-payment-container .container-uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-link-payment-container .container-uploader .list-files .file  button {
  background: none;
  border: none;
  outline: none;
}

.client-link-payment-container .container-uploader .list-files {
  width: 90%;  
  border-radius: 5px;  
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.client-link-payment-container .container-uploader .list-files .files {
  margin-top: 1vw;
  width: 100%;
}

.client-link-payment-container .container-uploader .list-files .file {
  background: #f0f0f0;
  border-radius: 3px;  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;  
  padding: 4px;
  margin: 10px 0;
}

.client-link-payment-container .container-uploader .list-files .file p {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}

.client-link-payment-container button.button-avance {
  margin-top: 50px;
  width: 100%;
  height: 45px;
  background: var(--secondary-color);
  color: #fff;
  border: none;
  outline: none;
  border-Radius: 8px;
  font-size: 16px;  
}

.client-link-payment-container button.button-back {
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--secondary-color);
  margin-bottom: 30px;  
  width: 90px;
  justify-content: space-between;
}

#container-modal {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  min-height: 400px;
  height: auto;
  background: #e2e2e2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 9px;
}

#container-modal h3 {
  color: var(--secondary-color);
}

#container-modal span {
  width: 70%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--secondary-color);
}

#container-modal .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 80%;  
}

#container-modal button {
  border: none;
  background-color: var(--primary-color);
  height: 30px;
  width: 90%;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  margin-top: 25px;
  padding: 0 20px;
  cursor: pointer;  
}